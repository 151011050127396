var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "meetingContent" }, [
    _vm.showWeixinTips
      ? _c("div", { staticClass: "weixinTips" }, [
          _c("img", {
            staticClass: "weixinTipsImg",
            attrs: {
              src: require("../../assets/images/meeting/weixinTips.png")
            }
          })
        ])
      : _vm._e(),
    _c("div", { staticClass: "page-background" }),
    _c("div", { staticClass: "page-bottom" }, [
      _c("div", { staticClass: "bottom-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "content-body" }, [_vm._v("Melinked")]),
        _c("div", { staticClass: "content-tips" }, [
          _vm._v("\n        " + _vm._s(_vm.tips) + "\n      ")
        ]),
        _c("div", { staticClass: "content-footer" }, [
          _c(
            "div",
            {
              staticClass: "page-button",
              on: {
                click: function($event) {
                  return _vm.openApp(true)
                }
              }
            },
            [
              !_vm.showLoading
                ? [_vm._v(" OPEN ")]
                : [
                    _c("van-loading", {
                      attrs: { type: "spinner", size: "20" }
                    })
                  ]
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-header" }, [
      _c("div", { staticClass: "melinkedIcon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }