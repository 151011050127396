<template>
  <div class="meetingContent">
    <div class="weixinTips" v-if="showWeixinTips">
      <img
        class="weixinTipsImg"
        :src="require('../../assets/images/meeting/weixinTips.png')"
      />
    </div>
    <div class="page-background"></div>
    <div class="page-bottom">
      <div class="bottom-content">
        <div class="content-header">
          <div class="melinkedIcon"></div>
        </div>
        <div class="content-body">Melinked</div>
        <div class="content-tips">
          {{ tips }}
        </div>
        <div class="content-footer">
          <div class="page-button" @click="openApp(true)">
            <template v-if="!showLoading"> OPEN </template>
            <template v-else>
              <van-loading type="spinner" size="20" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CallApp from "callapp-lib";
export default {
  name: "meetingPage",
  data() {
    return {
      fallbackLink: "",
      tips: "",
      timer: null,
      showLoading: false,
      showWeixinTips: false,
    };
  },
  async mounted() {
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.showWeixinTips = true;
      return;
    } else {
      this.showWeixinTips = false;
    }
    await this.bindTheListenerEvent();
    const u = navigator.userAgent,
      app = navigator.appVersion;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.fallbackLink =
        "https://itunes.apple.com/cn/app/melinked/id1047458085?mt=8";
    } else {
      this.fallbackLink =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.melink.android";
    }
    this.openApp(false);
  },
  methods: {
    goAppStore() {
      window.location.href = this.fallbackLink;
    },
    openApp(needGoAppStore) {
      clearTimeout(this.timer);
      const u = navigator.userAgent,
        app = navigator.appVersion;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      this.showLoading = needGoAppStore;
      const options = {
        scheme: {
          protocol: "melinked",
        },
        appstore: "https://itunes.apple.com/cn/app/melinked/id1047458085?mt=8",
        yingyongbao:
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.melink.android",
        fallback: `#${this.$route.path}`,
      };
      const callLib = new CallApp(options);
      (async () => {
        await this.goAppStoreDelay(needGoAppStore);
        if (isiOS) {
          callLib.open({
            path: "melinkedHome",
            callback: () => {},
          });
        } else {
          callLib.open({
            path: "melinkedmeeting",
            callback: () => {},
          });
        }
      })();
    },
    // 绑定页面是否隐藏的监听事件
    bindTheListenerEvent() {
      return new Promise((resolve, reject) => {
        document.addEventListener(
          "visibilitychange",
          this.visibilitychange,
          false
        );
        document.addEventListener(
          "webkitvisibilitychange",
          this.visibilitychange,
          false
        );
        window.addEventListener(
          "pagehide",
          () => {
            this.showLoading = false;
            clearTimeout(this.timer);
          },
          false
        );
        resolve("success");
      });
    },
    // 判断页面是否隐藏了
    visibilitychange() {
      const tag = document.hidden || document.webkitHidden;
      this.showLoading = false;
      tag && clearTimeout(this.timer);
    },
    // 定时跳转进应用商店
    goAppStoreDelay(needGoAppStore) {
      return new Promise((resolve, reject) => {
        this.timer = setTimeout(() => {
          if (needGoAppStore) {
            this.goAppStore();
          }
        }, 2500);
        resolve("success");
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.meetingContent {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: hidden;
  background: #fff;

  .weixinTips {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);

    .weixinTipsImg {
      width: 100%;
      max-width: 292px;
      position: absolute;
      top: 0;
      right: 15px;
    }
  }

  .page-background {
    width: 100vw;
    height: 65vh;
    background: url('../../assets/meeting/meetingBg.png') no-repeat;
    background-size: 100% auto;
  }

  .page-bottom {
    width: 100%;
    height: 35vh;
    position: relative;
    top: -6vh;

    &::after {
      content: '';
      width: 140%;
      height: 100%;
      position: absolute;
      left: -20%;
      top: 0;
      border-radius: 50% 50% 0 0;
      background: #fff;
    }

    .bottom-content {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 10;
      top: -5vh;

      .content-header {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        position: relative;

        .melinkedIcon {
          width: 21.5vw;
          height: 21.5vw;
          background: url('../../assets/meeting/meetingIcon.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .content-body {
        width: 100%;
        padding: 3vh 0 0 0;
        font-size: 18px;
        text-align: center;
        font-weight: bold;
      }

      .content-tips {
        height: 8vh;
        width: 100%;
        display: flex;
        padding: 0 5vw;
        font-size: 12px;
        overflow: hidden;
        word-break: normal;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        word-wrap: break-word;
      }

      .content-footer {
        width: 100%;
        height: 5.41vh;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .page-button {
          width: 42vw;
          height: 100%;
          border-radius: 2.705vh;
          color: #fff;
          text-align: center;
          line-height: 5.41vh;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-child(1) {
            background: #2FBF5D;
          }
        }
      }
    }
  }
}
</style>
<style lang="stylus">
.meetingContent {
  .van-loading {
    height: auto !important;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    padding-top: 0 !important;
    justify-content: center;
    color: #FFFFFF;
  }
}
</style>